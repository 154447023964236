$.fn.extend({
	customSelect:function(options) {
		var obj = this;
		var pluginName = "select";
		var defaults = {
			select: "n-" + pluginName
			,btn: "nh-" + pluginName
			,arrow: "<i aria-hidden='true' class='arrow icon-arrow-down'></i></span>"
		};
		var options = $.extend(true,{},defaults, options);
		return obj.each(function(){
			var $s = $(this ),
			$p = $s.parent().addClass(options.select );

			/* check if opacity value matches what's in the CSS to limit the enhancement to browsers that support opacity in the same way (otherwise the select may only partially enhance and be unusable) */
			if ($s.css("opacity" ) >= 0.001 ) {
				var getSelectValue = function(){
					return $s[ 0 ].options[ $s[ 0 ].selectedIndex ].text;
				}
				,$btn = $("<span class='"+ options.btn +"'>"+getSelectValue()+options.arrow)
				,getSelectStatus = function(){
					if ($s.prop('readonly')) {
						$btn.addClass('readonly').removeClass('disabled');
					}
					else if ($s.prop('disabled')) {
						$btn.addClass('disabled').removeClass('readonly');
					}
					else {
						$btn.removeClass('readonly disabled');
					}
				};
				getSelectStatus();
				$s.before($btn).on({
					change: function(){
						$btn.html(getSelectValue()+options.arrow);
					}
					,updateprop: function(){
						getSelectStatus();
						$btn.html(getSelectValue()+options.arrow);
					}
					,focus: function(){
						$btn.addClass("nh-focus" );
					}
					,blur: function(){
						$btn.removeClass("nh-focus" );
					}
				});

			}
			else {
				$s.css("opacity", "1" );
			};
		});
	}	// .customSelect(options);
});